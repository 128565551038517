
const typeCarsLoaded = 'CARS_LOADED';
const typeCountCarsLoaded = 'COUNT_CARS_LOADED';
const typeCarsForTodayLoaded = 'CARS_FOR_TODAY';
const typeSelectedIdCarLoaded = 'SELECT_ID_CAR';
const typeCarsForTomorrowLoaded = 'CARS_FOR_TOMORROW';
const typeCarsOrdersForRangeLoaded = 'CARS_ORDERS_FOR_RANGE';
const typeTextSearchCarLoaded = 'TEXT_SEARCH_CAR';
const typeClearFoundCars = 'CLEAR_FOUND_CARS';
const typeCarsSearchFetching = 'CARS_SEARCH_FETCHING';
const typeFoundCarsLoaded = 'FOUND_CARS';
const typeGetSvHeaderLoaded = 'SV_HEADER';
const typeGetCarDetailsLoaded = 'GET_CAR_DETAILS';
const typeCarsNotFullyBlocksLoaded = 'GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT';
const typeCarsFullyBlocksCountLoaded = 'GET_CARS_WITH_FULLY_BLOCKS_COLLECT_COUNT';
const typeCarsNotFullyBlocksCountLoaded = 'GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT_COUNT';
const typeCarsFullyBlocksLoaded = 'GET_CARS_WITH_FULLY_BLOCKS_COLLECT';
const typeCarsNotFullyOverdueCountLoaded = 'GET_COUNT_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT';
const typeCarsNotFullyOverdueLoaded = 'GET_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT';
const typeCarIdCollectLoaded = 'SET_CAR_ID_COLLECT_SELECT';
const typeFetchCarDetailsSuccess = 'FETCH_CAR_DETAILS_SUCCESS';
const typeGetFetchCollectBlocksError = 'GET_FETCH_COLLECT_BLOCKS_ERROR';
const typeCarCollectBlocksLoaded = 'GET_CAR_COLLECT_BLOCKS_LOADED';
const typeCollectBlocksSuccess = 'FETCH_COLLECT_BLOCK_SUCCESS';
const typeCompaniesInsuranceLoaded = 'SET_COMPANIES_INSURANCE';
const typeSeasonsPeriodsLoaded = 'GET_SEASONS_AND_PERIODS';
const typeSeasonsPeriodsErrorLoaded = 'SET_ERROR_GET_SEASONS_AND_PERIODS';
const typeBlockInterestReasonLoaded = 'GET_BLOCK_INTERESTS_REASON_TEMPLATE';
const typeSetShowAnchors = 'IS_SHOW_ANCHORS_COLLECT_DATA_FIELDS';
const typeSetShowSecretary = 'IS_SHOW_SECRETARY_COLLECT_DATA_FIELDS';
const typeCarImageUpdated = 'UPDATE_CAR_IMAGE';
const typeCarImageDeleted = 'DELETE_CAR_IMAGE';
const typeCarImagesAdded = 'ADD_CAR_IMAGES';
const typeChangeCarChangingImagesIds = 'CHANGE_CAR_CHANGING_IMAGES_IDS';
const typeChangeCarSettingImagesCount = 'CHANGE_CAR_SETTING_IMAGES_COUNT';
const typeCarDetailsUploaded = 'UPDATE_CAR_DETAILS';
const typeCarDetailsErrorUploaded = 'UPDATE_CAR_DETAILS_ERROR';
const typeCarInsuranceDeadlineUpdate = 'UPDATE_CAR_INSURANCE_DEADLINE';
const typeCarInsuranceDeadlineUpdateError = 'UPDATE_CAR_INSURANCE_DEADLINE_ERROR';
const typeCarInsuranceUpdate = 'UPDATE_CAR_INSURANCE';
const typeCarInsuranceUpdateError = 'UPDATE_CAR_INSURANCE_ERROR';
const typeCarOccupancyRateUpdate = 'UPDATE_CAR_OCCUPANCY_RATE';
const typeCarImageAdded = 'ADD_CAR_IMAGE';
const typeChangeSavingMainImageCarsIds = 'CHANGE_SAVING_CAR_MAIN_IMAGE_CARS_IDS';
const typeRequestCarMileage = 'REQUEST_CAR_MILEAGE';
const typeReceiveCarMileage = 'RECEIVE_CAR_MILEAGE';
const typeCalculateMileageInMiles = 'CALCULATE_MILEAGE_IN_MILES';
const typeResetCarMileage = 'RESET_CAR_MILEAGE';
const typeCarInterestsUpdate = 'UPDATE_CAR_INTERESTS';
const typeCarReasonsUpdate = 'UPDATE_CAR_REASONS';
const typeCarReasonsUpdateError = 'UPDATE_CAR_REASONS_ERROR';
const typeCarInterestsUpdateError = 'UPDATE_CAR_INTERESTS_ERROR';
const typeCarInterestDelete = 'CAR_INTEREST_DELETE';
const typeCarOilUpdate = 'UPDATE_CAR_OIL';
const typeCarOilUpdateError = 'UPDATE_CAR_OIL_ERROR';
const typeCarOilMonthsUpdate = 'UPDATE_CAR_OIL_MONTH';
const typeCarOilMonthsUpdateError = 'UPDATE_CAR_OIL_MONTH_ERROR';
const typeCarTiresUpdate = 'UPDATE_CAR_TIRES';
const typeCarTiresUpdateError = 'UPDATE_CAR_TIRES_ERROR';
const typeCarClientSeasonalPreferenceUpdate = 'UPDATE_CAR_CLIENT_SEASONAL_PREFERENCE';
const typeCarClientSeasonalPreferenceUpdateError = 'UPDATE_CAR_CLIENT_SEASONAL_PREFERENCE_ERROR';
const typeLogout = 'LOGOUT';
const typeFetchError = 'FETCH_ERROR';
const typeAddOffsetNotCollectCarsLoaded = 'ADD_OFFSET_NOT_COLLECT_CARS';
const typeAddOffsetNotCollectOverdueCarsLoaded = 'ADD_OFFSET_NOT_COLLECT_OVERDUE_CARS';
const typeResetCarId = 'RESET_CAR_ID';
const typeResetCarCollectId = 'RESET_CAR_COLLECT_ID';
const typeCarOilIsForTimeLoadSuccess = 'CAR_OIL_IS_FOR_TIME_SUCCESS';
const typeCarIsExactlyOilLastMileageSuccess = 'CAR_OIL_IS_EXACTLY_LAST_MILEAGE_SUCCESS';
const typeCarIsExactlyOilLastTimeSuccess = 'CAR_OIL_IS_EXACTLY_LAST_TIME_SUCCESS'; // ToDo delete after
const typeCarIsReShoeTiresSuccess = 'CAR_TIRES_IS_RE_SHOE_SUCCESS';
const typeCarIsDoWheelAlignmentSuccess = 'CAR_TIRES_IS_DO_ALIGNMENT_SUCCESS';
const typeCarPostInterestsSuccess = 'CAR_POST_INTERESTS_SUCCESS';
const typeFetchError404 = 'ERROR_404_REDIRECT';
const typeOwnersLoaded = 'GET_OWNERS_COMPANY';
const typeOwnersLoadedError = 'GET_OWNERS_COMPANY_ERROR';
const typeFetchOwnersCompanySuccess = 'FETCH_OWNERS_COMPANY_SUCCESS';
const typeSearchCarsError = 'SEARCH_CARS_ERROR';
const typeAddOwnerToCompany = 'ADD_OWNER_TO_COMPANY';
const typeDeleteOwnerFromCompany = 'DELETE_OWNER_FROM_COMPANY';
const typeUpdateOwnerCompany = 'UPDATE_OWNER_COMPANY';
const typeSetDataForNewOwner = 'SET_DATA_FOR_NEW_OWNER';
const typeFetchPartnerGroupRoles = 'GET_PARTNER_GROUP_ROLES';
const typeClearDataSetOwner = 'CLEAR_DATA_OWNER';
const typeSetOwnerId = 'SET_OWNER_ID';
const typeSetUpdateDataOwner = 'SET_UPDATE_DATA_OWNER';
const typeClearDataUpdateOwner = 'CLEAR_DATA_UPDATE_OWNER';
const typeGetAllResources = 'GET_ALL_RESOURCES';
const typeSetAllResourcesError = 'SET_ALL_RESOURCES_ERROR';
const typeSetAllResourcesLoading = 'SET_ALL_RESOURCES_ERROR';
const typeSetResource = 'SET_RESOURCE';
const typeListCollectResourceFilterSuccess = 'LIST_COLLECT_RESOURCE_FILTER_SUCCESS';
const typeListCollectResourceFilterError = 'LIST_COLLECT_RESOURCE_FILTER_ERROR';
const typeCarsFullyBlocksClear = 'CAR_NOT_FULLY_BLOCKS_CLEAR';
const typeShowBtnNotCollect = 'SHOW_BTN_NOT_COLLECT_INFO_CARS';
const typeShowBtnOverdue = 'SHOW_BTN_OVERDUE_COLLECT_INFO_CARS';
const typeClearOffsetLimitCar = 'CLEAR_OFFSET_LIMIT_CAR';
const typeClearOffsetLimitCarOverdue = 'CLEAR_OFFSET_LIMIT_CAR_OVERDUE';
const typeSaveEntryPoint = 'SAVE_ENTRY_POINT';
const typeToggleResourceFilter = 'TOGGLE_RESOURCE_FILTER';
const typeGetAllResourceCompany = 'GET_ALL_RESOURCE_COMPANY';
const typeErrorFetchAllResourceCompany = 'ERROR_FETCH_ALL_RESOURCE_COMPANY';
const typeAllResourceCompanySuccess = 'ALL_RESOURCE_COMPANY_SUCCESS';
const typeSetReasonsToState = 'SET_REASONS_TO_STATE';
const typeSetErrorReasons = 'SET_ERROR_REASONS';
const typeReasonsSuccess = 'REASONS_SUCCESS';
const typeDeleteReasonFromBlock = 'DELETE_REASON_FROM_BLOCK';
const typeDeleteErrorReasonFromBlock = 'DELETE_ERROR_REASON_FROM_BLOCK';
const typeDeleteReasonFromBlockSuccess = 'DELETE_REASON_FROM_BLOCK';
const typeSetNewInterestReason = 'SET_NEW_INTEREST_REASON';
const typeNewInterestReasonSuccess = 'NEW_INTEREST_REASON_SUCCESS';
const typeSetErrorNewInterestReason = 'SET_NEW_INTEREST_REASON_ERROR';
const typeDeleteReason = 'DELETE_REASON';
const typeDeleteErrorReason = 'DELETE_ERROR_REASON';
const typeDeleteReasonSuccess = 'DELETE_REASON_SUCCESS';
const typeSetNewReason = 'SET_NEW_REASON';
const typeNewReasonSuccess = 'NEW_REASON_SUCCESS';
const typeSetErrorNewReason = 'SET_NEW_REASON_ERROR';
const typeSetUpdateReason = 'SET_UPDATE_REASON';
const typeUpdateBlockSuccess = 'UPDATE_BLOCK_SUCCESS';
const typeErrorUpdateBlock = 'UPDATE_BLOCK_ERROR';
const typeSetUpdateBlock = 'SET_UPDATE_BLOCK';
const typeSetUpdateSeason = 'SET_UPDATE_SEASON_TIRES';
const typeErrorUpdateSeason = 'UPDATE_SEASON_TIRES_ERROR';
const typeUpdateSeasonSuccess = 'UPDATE_SEASON_TIRES_SUCCESS';
const typeSetUpdateSeasonPeriods = 'SET_UPDATE_SEASON_PERIODS_TIRES';
const typeErrorUpdateSeasonPeriods = 'UPDATE_SEASON_PERIODS_TIRES_ERROR';
const typeUpdateSeasonPeriodsSuccess = 'UPDATE_SEASON_PERIODS_TIRES_SUCCESS';
const typeSetSeasons = 'SET_SEASONS';
const typeSetPacketsSeasons = 'SET_PACKETS_SEASONS';
const typeSetErrorPacketsSeasons = 'SET_ERROR_PACKETS_SEASONS';
const typePacketsSeasonsSuccess = 'SET_PACKETS_SEASONS_SUCCESS';
const typeDeleteSeason = 'DELETE_SEASON';
const typeSaveSeasonsPeriod = 'SAVE_SEASONS_PERIOD';
const typeDeleteSeasonsPeriod = 'DELETE_SEASONS_PERIOD';
const typeChangeFlagDoNotCallClient = 'CHANGE_FLAG_DO_NOT_CALL_CLIENT';
const typeErrorSetFlagDoNotCallClient = 'SET_FLAG_DO_NOT_CALL_CLIENT_ERROR';
const typeSetFlagDoNotCallClientSuccess = 'SET_FLAG_DO_NOT_CALL_CLIENT_SUCCESS';
const typeUpdateDataOilSuccess = 'UPDATE_DATA_OIL_SUCCESS';
const typeSetTextSearchVisitors = 'SET_TEXT_SEARCH_VISITORS';
const typeSaveVisitors = 'SAVE_VISITORS';
const typeSetNewVisitorsLimitOffset = 'SET_NEW_VISITORS_LIMIT_OFFSET';
const typeSetDatesFilterForVisitors = 'SET_DATES_FILTER_FOR_VISITORS';
const typeClearVisitors = 'CLEAR_VISITORS';
const typeNearestVisitorsSuccess = 'NEAREST_VISITORS_SUCCESS';
const typeNearestVisitorsError = 'NEAREST_VISITORS_ERROR';
const typeClearFoundVisitors = 'CLEAR_FOUND_VISITORS';
const typeSetFoundedVisitors = 'SET_FOUNDED_VISITORS';
const typeAddVisitorsSearchOffset = 'ADD_VISITORS_SEARCH_OFFSET';
const typeSearchVisitorsError = 'SEARCH_VISITORS_ERROR';
const typeSearchVisitorsSuccess = 'SEARCH_VISITORS_SUCCESS';
const typeSetVisitorId = 'SET_VISITOR_ID';
const typeSetVisitor = 'SET_VISITOR';
const typeSetVisitorAvatar = 'SET_VISITOR_AVATAR';
const typeFetchVisitorError = 'FETCH_VISITOR_ERROR';
const typeFetchVisitorSuccess = 'FETCH_VISITOR_SUCCESS';
const typeFetchInviteError = 'FETCH_INVITE_ERROR';
const typeFetchInviteSuccess = 'FETCH_INVITE_SUCCESS';
const typeFetchActivityError = 'FETCH_ACTIVITY_ERROR';
const typeFetchActivitySuccess = 'FETCH_ACTIVITY_SUCCESS';
const typeSetDataChangeVisitor = 'SET_DATA_CHANGE_VISITOR';
const typeSetNewDataVisitor = 'SET_NEW_DATA_VISITOR';
const typeSetNewDataLoader = 'SET_NEW_DATA_LOADER';
const typeSetNewDataVisitorSuccess = 'SET_NEW_DATA_VISITOR_SUCCESS';
const typeSetNewDataVisitorError = 'SET_NEW_DATA_VISITOR_ERROR';
const typeClearNewDataVisitor = 'CLEAR_NEW_DATA_VISITOR';
const typeSetStatusesConfig = 'SET_CLIENT_STATUSES_CONFIG';
const typeSetStatusesConfigSuccess = 'SET_CLIENT_STATUSES_CONFIG_SUCCESS';
const typeSetStatusesConfigError = 'SET_CLIENT_STATUSES_CONFIG_ERROR';
const typeDeleteClientFromCar = 'DELETE_CLIENT_FROM_CAR';
const typeSetNewCarClientStatuses = 'SET_NEW_CAR_CLIENT_STATUSES';
const typeSetNewCarClientStatusesError = 'SET_NEW_CAR_CLIENT_STATUSES_ERROR';
const typeSetNewCarClientStatusesSuccess = 'SET_NEW_CAR_CLIENT_STATUSES_SUCCESS';
const typeSeasonsPeriodsSuccess = 'SEASON_PERIODS_SUCCESS';
const typeSetMessagesErrorSendStatus = 'SET_MESSAGES_ERROR_SEND_STATUS';
const typeSetCreateNewClientInCarError = 'SET_CREATE_NEW_CLIENT_IN_CAR_ERROR';
const typeSetCreateNewClientInCarSuccess = 'SET_CREATE_NEW_CLIENT_IN_CAR_SUCCESS';
const typeFetchError500 = 'FETCH_ERROR_500';
const typeFetchError422 = 'FETCH_ERROR_422';
const typeClearErrorsCommon = 'CLEAR_ERRORS_COMMON';
const typeSaveReservePostCreateNewClientData = 'SAVE_POST_CREATE_NEW_CLIENT_DATA';
const typeSetMileageCollect = 'SET_MILEAGE_COLLECT';
const typeSetIsKmMileage = 'SET_IS_KM_MILEAGE';
const typeUpdateMileageCollectSuccess = 'UPDATE_MILEAGE_COLLECT_SUCCESS';
const typeUpdateMileageCollectError = 'UPDATE_MILEAGE_COLLECT_ERROR';
const typeCarsOrdersError = 'CARS_ORDERS_ERROR';
const typeCarsOrdersSuccess = 'CARS_ORDERS_SUCCESS';
const typeSvBooksCountSuccess = 'SV_BOOKS_COUNT_SUCCESS';
const typeSvBooksCountError = 'SV_BOOKS_COUNT_ERROR';
const typeChangeNewCallClient = 'SET_DATA_CHANGE_CLIENT';
const typeGetSecretaryInfoSuccess = 'GET_SECRETARY_INFO_SUCCESS';
const typeGetSecretaryInfoError = 'GET_SECRETARY_INFO_ERROR';
const typeSetSecretaryMileageInfo = 'SET_SECRETARY_MILEAGE_INFO';
const typeSetSecretaryMileageConnectionsInfo = 'SET_SECRETARY_MILEAGE_CONNECTIONS_INFO';
const typePutSecretaryInfoSuccess = 'PUT_SECRETARY_INFO_SUCCESS';
const typePutSecretaryInfoError = 'PUT_SECRETARY_INFO_ERROR';
const typeSetSecretaryConnectionAfterUpdate = 'SET_SECRETARY_CONNECTION_AFTER_UPDATE';
const typeSetSecretaryConnectionCommonAfterUpdate = 'SET_SECRETARY_CONNECTION_COMMON_AFTER_UPDATE';
const typeSetSecretaryInfoAfterUpdate = 'SET_SECRETARY_INFO_AFTER_UPDATE';
const typeSetSecretaryCommonInfoAfterUpdateError = 'SET_SECRETARY_COMMON_INFO_AFTER_UPDATE';
const typeDeleteSecretaryByTypeSuccess = 'DELETE_SECRETARY_BY_TYPE_SUCCESS';
const typeDeleteSecretaryByTypeError = 'DELETE_SECRETARY_BY_TYPE_ERROR';
const typeFetchClientCarsSuccess = 'FETCH_CLIENT_CARS_SUCCESS';
const typeFetchClientCarsError = 'FETCH_CLIENT_CARS_ERROR';
const typeFetchClientsSuccess = 'FETCH_CLIENTS_SUCCESS';
const typeFetchClientsError = 'FETCH_CLIENTS_ERROR';
const typeAddSearchClientsData = 'ADD_SEARCH_CLIENTS_DATA';
const typeSetFoundClientsData = 'SET_FOUND_CLIENTS_DATA';
const typeClearFoundClientsData = 'CLEAR_FOUND_CLIENTS_DATA';
const typeSearchClientsStatusesSuccess = 'SEARCH_CLIENTS_STATUSES_SUCCESS';
const typeSetInsurance = 'SET_INSURANCE';
const typeChangeInsurance = 'CHANGE_INSURANCE';
const typeCreateInsurance = 'CREATE_INSURANCE';
const typeDeleteInsurance = 'DELETE_INSURANCE';
const typeClientIdCollectSet = 'SET_CLIENT_ID_COLLECT';
const typeSeasonPeriodTimes = 'SEASON_PERIOD_TIMES';
const typeSeasonPeriodTimesError = 'SEASON_PERIOD_TIMES_ERROR';
const typeSeasonPeriodTimesSuccess = 'SEASON_PERIOD_TIMES_SUCCESS';
const typeSeasonPeriodTimesStore = 'SEASON_PERIOD_TIMES_STORE';
const typeSeasonPeriodTimesDelete = 'SEASON_PERIOD_TIMES_DELETE';
const typeCarDetailsSuccess = 'CAR_DETAILS_SUCCESS';
const typeCarDetailsError = 'CAR_DETAILS_ERROR';
const typeSetMileageAt = 'SET_MILEAGE_AT';
const typeGetDataForPreReserve = 'GET_INVITE_PRE_RESERVE';
const typeDataForPreReserveError = 'INVITE_DATE_PRE_RESERVE_ERROR';
const typeDataForPreReserveSuccess = 'INVITE_DATE_PRE_RESERVE_SUCCESS';
const typeCreateDataForPreReserve = 'POST_INVITE_PRE_RESERVE';
const typeSetNoticePreReserve = 'SET_NOTICE_PRE_RESERVE';
const typeSetInviteFrom1C = 'SET_INVITE_FROM_1C';
const typeGetInviteFrom1CSuccess = 'GET_INVITE_FROM_1C_SUCCESS';
const typeGetInviteFrom1CError = 'GET_INVITE_FROM_1C_ERROR';
const typeClearInviteFrom1C = 'CLEAR_INVITE_FROM_1C';
const typeClearPreReserveInvites = 'CLEAR_PRE_RESERVE_INVITES';
const typeSaveReserveOrOrder = 'SAVE_RESERVE_OR_ORDER';
const typeSaveReportsCollectionSheets = 'SAVE_REPORTS_COLLECTION_SHEETS';
const typeSetNewTimeRangeReports = 'SET_NEW_TIME_RANGE_REPORTS';
const typeSaveCollectionSheetHistory = 'SAVE_COLLECTION_SHEET_HISTORY';
const typeSaveCollectionSheetDetails = 'SAVE_COLLECTION_SHEET_DETAILS';
const typeSaveCollectionSheetHistoryError = 'SAVE_COLLECTION_SHEET_HISTORY_ERROR';
const typeSaveCollectionSheetHistorySuccess = 'SAVE_COLLECTION_SHEET_HISTORY_SUCCESS';
const typeSetOwnerIdSheets = 'SET_OWNER_ID_SHEETS';
const typeSetStatusesPreferSheets = 'SET_STATUSES_PREFER_SHEETS';
const typeSetCollectionSheets = 'SET_COLLECTION_SHEETS';
const typeGetYearReportProlongation = 'SET_YEAR_REPORT_PROLONGATION';
const typeGetYearReportProlongationSuccess = 'SET_REPORT_PROLONGATION_SUCCESS';
const typeGetYearReportProlongationError = 'SET_REPORT_PROLONGATION_ERROR';
const typeSetYearForReportProlongation = 'SET_YEAR_FOR_REPORT_PROLONGATION';
const typeSetRangeProlongationInAction = 'SET_RANGE_PROLONGATION_IN_ACTION';
const typeSetReportProlongationInActionSuccess = 'SET_PROLONGATION_IN_ACTION_SUCCESS';
const typeSetReportProlongationInActionError = 'SET_PROLONGATION_IN_ACTION_ERROR';
const typeSetReportProlongationInAction = 'SET_REPORT_PROLONGATION_IN_ACTION';
const typeSetProlongationData = 'SET_PROLONGATION_IN_ACTION_DATA';
const typeSetProlongationFormatChunks = 'SET_PROLONGATION_FORMAT_CHUNKS';
const typeChangeUnionFlagProlongation = 'CHANGE_UNION_FLAG_PROLONGATION';
const typeUpdateDataOilFieldsSuccess = 'UPDATE_DATA_OIL_FIELDS_SUCCESS';
const typeSetOilReferences = 'SET_OIL_REFERENCES';
const typeUpdateOilReferences = 'UPDATE_OIL_REFERENCES';
const typeDeleteOilReferences = 'DELETE_OIL_REFERENCES';
const typeUpdateOilReferencesSuccess = 'UPDATE_OIL_OIL_REFERENCES_SUCCESS';
const typeUpdateOilReferencesError = 'UPDATE_OIL_OIL_REFERENCES_ERROR';
const typeSetProlongationFlag = 'SET_PROLONGATION_FLAG';
const typeUpdateProlongationFlag = 'UPDATE_PROLONGATION_FLAG';
const typeProlongationFlagSuccess = 'PROLONGATION_FLAG_SUCCESS';
const typeProlongationFlagError = 'PROLONGATION_FLAG_ERROR';
const typeGetPreReservesSuccess = 'GET_PRE_RESERVES_SUCCESS';
const typeGetPreReservesError = 'GET_PRE_RESERVES_ERROR';
const typeSetPreReserves = 'SET_PRE_RESERVES';
const typeDeletePreReserve = 'DELETE_PRE_RESERVE';
const typeUpdatePreReserves = 'UPDATE_PRE_RESERVE';
const typeSetIsOpenMinHeader = 'SET_IS_OPEN_MIN_HEADER';
const typeSetPreReservesFromReport = 'SET_PRE_RESERVES_FOR_REPORT';
const typeAddToPreReservesFromReport = 'ADD_TO_PRE_RESERVES_FOR_REPORT';
const typeClearPreReservesFromReport = 'CLEAR_PRE_RESERVES_FOR_REPORT';
const typeSetPreReservesLimitOffset = 'SET_PRE_RESERVES_LIMIT_OFFSET';
const typeSetModeProlongation = 'SET_MODE_PROLONGATION';
const typeSetAverageDailyMileage = 'SET_AVERAGE_DAILY_MILEAGE';
const typeSetMonthForChangeOil = 'SET_MONTH_FOR_CHANGE_OIL';
const typeSetCompanyInfo = 'SET_COMPANY_INFO';
const typeSetFocusMileage = 'SET_FOCUS_MILEAGE';
const typeSetClientNotificationsSettings = 'SET_CLIENT_NOTIFICATIONS_SETTINGS';
const typeSetClientNotificationsSettingsError = 'SET_CLIENT_NOTIFICATIONS_SETTINGS_ERROR';
const typeSetClientNotificationsSettingsSuccess = 'SET_CLIENT_NOTIFICATIONS_SETTINGS_SUCCESS';
const typeSetPreReserve = 'PRE_RESERVE';
const typeCountProlongationInitiation = 'SET_COUNT_PROLONGATION_INITIATION';
const typeExportProlongationReportSuccess = 'EXPORT_PROLONGATION_REPORT_SUCCESS';

const typeSetPackets = 'SET_PACKETS';
const typeUpdateClientPacketCycle = 'UPDATE_CLIENT_PACKET_CYCLE';
const typeUpdateClientPacketCycleSuccess = 'UPDATE_CLIENT_PACKET_CYCLE_SUCCESS';
const typeUpdateClientPacketCycleError = 'UPDATE_CLIENT_PACKET_CYCLE_ERROR';

export {
    typeCarsLoaded,
    typeCountCarsLoaded,
    typeCarsForTodayLoaded,
    typeSelectedIdCarLoaded,
    typeCarsForTomorrowLoaded,
    typeTextSearchCarLoaded,
    typeFoundCarsLoaded,
    typeGetSvHeaderLoaded,
    typeGetCarDetailsLoaded,
    typeCarsNotFullyBlocksLoaded,
    typeCarsFullyBlocksCountLoaded,
    typeCarsFullyBlocksLoaded,
    typeCarsNotFullyBlocksCountLoaded,
    typeCarsNotFullyOverdueCountLoaded,
    typeCarsNotFullyOverdueLoaded,
    typeCarIdCollectLoaded,
    typeFetchCarDetailsSuccess,
    typeGetFetchCollectBlocksError,
    typeCarCollectBlocksLoaded,
    typeCollectBlocksSuccess,
    typeCompaniesInsuranceLoaded,
    typeSeasonsPeriodsLoaded,
    typeSeasonsPeriodsErrorLoaded,
    typeBlockInterestReasonLoaded,
    typeSetShowAnchors,
    typeSetShowSecretary,
    typeCarImageUpdated,
    typeCarImageDeleted,
    typeCarImagesAdded,
    typeChangeCarChangingImagesIds,
    typeChangeCarSettingImagesCount,
    typeCarDetailsUploaded,
    typeCarDetailsErrorUploaded,
    typeCarInsuranceDeadlineUpdate,
    typeCarInsuranceDeadlineUpdateError,
    typeCarInsuranceUpdate,
    typeCarInsuranceUpdateError,
    typeCarOccupancyRateUpdate,
    typeCarImageAdded,
    typeChangeSavingMainImageCarsIds,
    typeClearFoundCars,
    typeCarsSearchFetching,
    typeRequestCarMileage,
    typeReceiveCarMileage,
    typeCalculateMileageInMiles,
    typeResetCarMileage,
    typeCarInterestsUpdate,
    typeCarReasonsUpdate,
    typeCarReasonsUpdateError,
    typeCarInterestsUpdateError,
    typeCarInterestDelete,
    typeCarOilUpdate,
    typeCarOilUpdateError,
    typeCarOilMonthsUpdate,
    typeCarOilMonthsUpdateError,
    typeCarTiresUpdate,
    typeCarTiresUpdateError,
    typeCarClientSeasonalPreferenceUpdate,
    typeCarClientSeasonalPreferenceUpdateError,
    typeLogout,
    typeFetchError,
    typeAddOffsetNotCollectCarsLoaded,
    typeAddOffsetNotCollectOverdueCarsLoaded,
    typeResetCarId,
    typeResetCarCollectId,
    typeCarOilIsForTimeLoadSuccess,
    typeCarIsExactlyOilLastMileageSuccess,
    typeCarIsExactlyOilLastTimeSuccess,
    typeCarIsReShoeTiresSuccess,
    typeCarIsDoWheelAlignmentSuccess,
    typeCarPostInterestsSuccess,
    typeFetchError404,
    typeOwnersLoaded,
    typeOwnersLoadedError,
    typeFetchOwnersCompanySuccess,
    typeSearchCarsError,
    typeAddOwnerToCompany,
    typeDeleteOwnerFromCompany,
    typeUpdateOwnerCompany,
    typeSetDataForNewOwner,
    typeFetchPartnerGroupRoles,
    typeClearDataSetOwner,
    typeSetOwnerId,
    typeSetUpdateDataOwner,
    typeClearDataUpdateOwner,
    typeGetAllResources,
    typeSetAllResourcesError,
    typeSetAllResourcesLoading,
    typeSetResource,
    typeListCollectResourceFilterSuccess,
    typeListCollectResourceFilterError,
    typeCarsFullyBlocksClear,
    typeShowBtnOverdue,
    typeShowBtnNotCollect,
    typeClearOffsetLimitCarOverdue,
    typeClearOffsetLimitCar,
    typeSaveEntryPoint,
    typeToggleResourceFilter,
    typeGetAllResourceCompany,
    typeErrorFetchAllResourceCompany,
    typeAllResourceCompanySuccess,
    typeSetReasonsToState,
    typeSetErrorReasons,
    typeReasonsSuccess,
    typeDeleteReasonFromBlock,
    typeDeleteErrorReasonFromBlock,
    typeDeleteReasonFromBlockSuccess,
    typeSetNewInterestReason,
    typeNewInterestReasonSuccess,
    typeSetErrorNewInterestReason,
    typeDeleteReason,
    typeDeleteErrorReason,
    typeDeleteReasonSuccess,
    typeSetNewReason,
    typeNewReasonSuccess,
    typeSetErrorNewReason,
    typeSetUpdateReason,
    typeUpdateBlockSuccess,
    typeErrorUpdateBlock,
    typeSetUpdateBlock,
    typeSetUpdateSeason,
    typeErrorUpdateSeason,
    typeUpdateSeasonSuccess,
    typeSetUpdateSeasonPeriods,
    typeErrorUpdateSeasonPeriods,
    typeUpdateSeasonPeriodsSuccess,
    typeSetSeasons,
    typeSetPacketsSeasons,
    typeSetErrorPacketsSeasons,
    typePacketsSeasonsSuccess,
    typeDeleteSeason,
    typeSaveSeasonsPeriod,
    typeDeleteSeasonsPeriod,
    typeChangeFlagDoNotCallClient,
    typeErrorSetFlagDoNotCallClient,
    typeSetFlagDoNotCallClientSuccess,
    typeUpdateDataOilSuccess,
    typeSetTextSearchVisitors,
    typeSaveVisitors,
    typeSetNewVisitorsLimitOffset,
    typeSetDatesFilterForVisitors,
    typeClearVisitors,
    typeNearestVisitorsSuccess,
    typeNearestVisitorsError,
    typeClearFoundVisitors,
    typeSetFoundedVisitors,
    typeAddVisitorsSearchOffset,
    typeSearchVisitorsError,
    typeSearchVisitorsSuccess,
    typeSetVisitorId,
    typeSetVisitor,
    typeFetchVisitorError,
    typeFetchVisitorSuccess,
    typeSetDataChangeVisitor,
    typeSetNewDataVisitorSuccess,
    typeSetNewDataVisitorError,
    typeSetNewDataVisitor,
    typeClearNewDataVisitor,
    typeSetStatusesConfig,
    typeSetStatusesConfigSuccess,
    typeSetStatusesConfigError,
    typeDeleteClientFromCar,
    typeSetNewCarClientStatuses,
    typeSetNewCarClientStatusesError,
    typeSetNewCarClientStatusesSuccess,
    typeSeasonsPeriodsSuccess,
    typeSetMessagesErrorSendStatus,
    typeSetCreateNewClientInCarError,
    typeSetCreateNewClientInCarSuccess,
    typeFetchError500,
    typeFetchError422,
    typeClearErrorsCommon,
    typeSaveReservePostCreateNewClientData,
    typeSetMileageCollect,
    typeSetIsKmMileage,
    typeUpdateMileageCollectSuccess,
    typeUpdateMileageCollectError,
    typeCarsOrdersError,
    typeCarsOrdersSuccess,
    typeCarsOrdersForRangeLoaded,
    typeSvBooksCountSuccess,
    typeSvBooksCountError,
    typeChangeNewCallClient,
    typeGetSecretaryInfoSuccess,
    typeGetSecretaryInfoError,
    typeSetSecretaryMileageInfo,
    typeSetSecretaryMileageConnectionsInfo,
    typePutSecretaryInfoSuccess,
    typePutSecretaryInfoError,
    typeSetSecretaryConnectionAfterUpdate,
    typeSetSecretaryConnectionCommonAfterUpdate,
    typeSetSecretaryInfoAfterUpdate,
    typeSetSecretaryCommonInfoAfterUpdateError,
    typeDeleteSecretaryByTypeSuccess,
    typeDeleteSecretaryByTypeError,
    typeFetchInviteError,
    typeFetchInviteSuccess,
    typeFetchActivityError,
    typeFetchActivitySuccess,
    typeFetchClientCarsError,
    typeFetchClientCarsSuccess,
    typeFetchClientsError,
    typeFetchClientsSuccess,
    typeSetNewDataLoader,
    typeSetVisitorAvatar,
    typeAddSearchClientsData,
    typeSetFoundClientsData,
    typeClearFoundClientsData,
    typeSearchClientsStatusesSuccess,
    typeSetInsurance,
    typeChangeInsurance,
    typeCreateInsurance,
    typeDeleteInsurance,
    typeSeasonPeriodTimes,
    typeSeasonPeriodTimesError,
    typeSeasonPeriodTimesSuccess,
    typeSeasonPeriodTimesStore,
    typeSeasonPeriodTimesDelete,
    typeClientIdCollectSet,
    typeCarDetailsSuccess,
    typeCarDetailsError,
    typeSetMileageAt,
    typeGetDataForPreReserve,
    typeDataForPreReserveError,
    typeDataForPreReserveSuccess,
    typeCreateDataForPreReserve,
    typeSetNoticePreReserve,
    typeSetInviteFrom1C,
    typeGetInviteFrom1CSuccess,
    typeGetInviteFrom1CError,
    typeClearInviteFrom1C,
    typeClearPreReserveInvites,
    typeSaveReserveOrOrder,
    typeSaveReportsCollectionSheets,
    typeSetNewTimeRangeReports,
    typeSaveCollectionSheetHistory,
    typeSaveCollectionSheetHistoryError,
    typeSaveCollectionSheetHistorySuccess,
    typeSaveCollectionSheetDetails,
    typeSetOwnerIdSheets,
    typeSetStatusesPreferSheets,
    typeSetCollectionSheets,
    typeGetYearReportProlongation,
    typeGetYearReportProlongationSuccess,
    typeGetYearReportProlongationError,
    typeSetYearForReportProlongation,
    typeSetRangeProlongationInAction,
    typeSetReportProlongationInActionSuccess,
    typeSetReportProlongationInActionError,
    typeSetReportProlongationInAction,
    typeSetProlongationData,
    typeSetProlongationFormatChunks,
    typeChangeUnionFlagProlongation,
    typeUpdateDataOilFieldsSuccess,
    typeSetOilReferences,
    typeUpdateOilReferences,
    typeDeleteOilReferences,
    typeUpdateOilReferencesSuccess,
    typeUpdateOilReferencesError,
    typeSetProlongationFlag,
    typeUpdateProlongationFlag,
    typeProlongationFlagSuccess,
    typeProlongationFlagError,
    typeGetPreReservesSuccess,
    typeGetPreReservesError,
    typeSetPreReserves,
    typeDeletePreReserve,
    typeUpdatePreReserves,
    typeSetIsOpenMinHeader,
    typeSetPreReservesFromReport,
    typeAddToPreReservesFromReport,
    typeClearPreReservesFromReport,
    typeSetPreReservesLimitOffset,
    typeSetModeProlongation,
    typeSetAverageDailyMileage,
    typeSetMonthForChangeOil,
    typeSetCompanyInfo,
    typeSetFocusMileage,
    typeSetClientNotificationsSettings,
    typeSetClientNotificationsSettingsError,
    typeSetClientNotificationsSettingsSuccess,
    typeSetPreReserve,
    typeCountProlongationInitiation,
    typeExportProlongationReportSuccess,
    typeSetPackets,
    typeUpdateClientPacketCycle,
    typeUpdateClientPacketCycleSuccess,
    typeUpdateClientPacketCycleError
};
