import React, { useEffect, useRef, useState } from 'react';
import HeadAnchors from './components/HeadAnchors';
import style from './SheetCollectBody.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SetCarInterest } from 'components/Requests';
import { isEmpty, checkStatusExist } from 'utils';
import { setShowAnchors, setShowSecretary } from 'actions';
import SecretaryWeekDays from './components/Secretary/SecretaryWeekDays';
import AnchorsBlock from './components/AnchorsBlock';
import StatusesBlock from './components/StatusesBlock';
import MileageBlock from './components/MileageBlock';
import { MILEAGE_BLOCK_LABEL, STATUSES_BLOCK_LABEL } from '../../../constants/CarCollectBlocks';
import { getPreReserves } from '../../../reducers/preReserve';
import { setOpenMinHeader } from '../../../reducers/sheetCollectInfo';
import SheetCollectMinHeader from '../SheetCollectMinHeader';
import { getAllStatusesCarClients } from '../../../reducers/statusesClientCar';
import { STATUS_LPR, STATUS_MAIN_USER } from '../../../constants/statusesCarClient';
import { updateClientPacketCycle } from '../../../reducers/packet';

function findIdBlock(blocks, label) {
    let result = { id: 0, title: '' };
    if (blocks && Array.isArray(blocks)) {
        result = blocks.find(block => block.label === label) || result;
    }

    return {
        id: result.id,
        title: result.title
    };
}

const HeightBeforeBody = 51 + 56;

const SheetCollectBody = ({
    textHeaders,
    carCollectBlocks,
    carDetailsInfo,
    setShowAnchors,
    showAnchors,
    showSecretary,
    setShowSecretary,
    carIdCollect,
    clientIdCollect,
    prolongations,
    blocks,
    isOpenMinHeader,
    setOpenMinHeader,
    statuses,
    getAllStatusesCarClients,
    focusMileage,
    updateClientPacketCycle,
    updateProlongationsFlag = () => {}
}) => {
    const [serverBtns, setServerBtns] = useState(carDetailsInfo.car_selected_interests);
    const [isHiddenSecretary, setIsHiddenSecretary] = useState(false);
    const body = useRef(null);

    useEffect(() => {
        (!statuses || statuses.length === 0) && getAllStatusesCarClients();
    }, [getAllStatusesCarClients, statuses]);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [carIdCollect]);

    useEffect(() => {
        setIsHiddenSecretary(false);
    }, [carDetailsInfo]);

    const handleSetHiddenSecretary = (isHide) => {
        setIsHiddenSecretary(isHide);
    };

    useEffect(() => {
        setServerBtns(carDetailsInfo.car_selected_interests);
    }, [carDetailsInfo, serverBtns]);

    const isExistInServerBtns = serverBtns ? !isEmpty(serverBtns.filter((item) => {
        return (item.car_collect_block_id === (blocks && blocks[1].id));
    })) : false;

    const isExistInServerBtnsSecretary = serverBtns ? !isEmpty(serverBtns.filter((item) => {
        return (item.car_collect_block_id === (blocks && blocks[7].id) && item.client_id === clientIdCollect);
    })) : false;

    useEffect(() => {
        setShowAnchors(!isExistInServerBtns);
    }, [isExistInServerBtns, setShowAnchors]);

    useEffect(() => {
        const handleOnScrollWindow = () => {
            if (body && body.current) {
                if ((body.current.offsetTop - window.scrollY) < HeightBeforeBody) {
                    !isOpenMinHeader && setOpenMinHeader(true);
                } else {
                    isOpenMinHeader && setOpenMinHeader(false);
                }
            }
        };
        window.addEventListener('scroll', handleOnScrollWindow);
        return () => window.removeEventListener('scroll', handleOnScrollWindow);
    }, [carIdCollect, isOpenMinHeader, setOpenMinHeader, body]);

    useEffect(() => {
        setShowSecretary(!isExistInServerBtnsSecretary);
    }, [isExistInServerBtnsSecretary, setShowSecretary]);

    const handleUpdateProlongation = (value, id) => {
        carDetailsInfo?.id && clientIdCollect && updateProlongationsFlag({ carId: carDetailsInfo.id, clientId: clientIdCollect, blockId: id, isActivated: value });
    };

    const interestsSecretaryNotCollect = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[7] : null) : null;

    return (
        <>
            {
                isOpenMinHeader
                    ? <SheetCollectMinHeader />
                    : ''
            }
            <div className={style.sheetCollectBody} id={blocks && blocks[0].id} ref={body}>
                <MileageBlock block={blocks && findIdBlock(blocks, MILEAGE_BLOCK_LABEL)} carDetailsInfo={carDetailsInfo} focusMileage={focusMileage} />
                <StatusesBlock block={blocks && findIdBlock(blocks, STATUSES_BLOCK_LABEL)} carDetailsInfo={carDetailsInfo} />
                {
                    checkStatusExist(carDetailsInfo?.statuses || [], statuses, STATUS_LPR)
                        ? (
                            <AnchorsBlock
                                blocks={blocks}
                                textHeaders={textHeaders}
                                carDetailsInfo={carDetailsInfo}
                                showAnchors={showAnchors}
                                carCollectBlocks={carCollectBlocks}
                                prolongations={prolongations}
                                handleUpdateProlongation={handleUpdateProlongation}
                                handleUpdatePacketCycle={updateClientPacketCycle}
                            />
                        )
                        : ''
                }

                <div className={style.collectWrap + ' ' + style.marginBottom130} key='secretary' id='anchorSecretary'>
                    <HeadAnchors
                        bgStyle={style.headSecretary}
                        textHeader={textHeaders[1]}
                        id={blocks && blocks[7].id}
                        handleSetHidden={handleSetHiddenSecretary}
                        stateBool={isHiddenSecretary}
                    />
                    {
                        isHiddenSecretary
                            ? ''
                            : showSecretary
                                ? (
                                    <SecretaryWeekDays
                                        clientId={clientIdCollect || (carDetailsInfo && carDetailsInfo.clientVisitor && carDetailsInfo.clientVisitor.id)}
                                        carIdCollect={carIdCollect}
                                        isMainClientExist={checkStatusExist(carDetailsInfo?.statuses || [], statuses, STATUS_MAIN_USER)}
                                    />
                                )
                                : (
                                    <SetCarInterest
                                        key='secretarySetCarInterest'
                                        nameBlock=''
                                        interestsReasons={interestsSecretaryNotCollect}
                                        id={blocks && blocks[7].id}
                                        anchorsNotCollect
                                    />
                                )
                    }
                </div>
            </div>
        </>
    );
};

SheetCollectBody.propTypes = {
    textHeaders: PropTypes.array.isRequired,
    carCollectBlocks: PropTypes.object,
    carDetailsInfo: PropTypes.object,
    setShowAnchors: PropTypes.func,
    setShowSecretary: PropTypes.func,
    showAnchors: PropTypes.bool,
    showSecretary: PropTypes.bool,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    blocks: PropTypes.array,
    updateProlongationsFlag: PropTypes.func,
    setOpenMinHeader: PropTypes.func,
    prolongations: PropTypes.array,
    statuses: PropTypes.array,
    getAllStatusesCarClients: PropTypes.func,
    updateClientPacketCycle: PropTypes.func,
    isOpenMinHeader: PropTypes.bool,
    focusMileage: PropTypes.bool
};

const mapStateToProps = ({
    carDetailsState: { carDetailsInfo, showAnchors, showSecretary, carIdCollect, clientIdCollect },
    templatesOfFrameState: { textHeaders, carCollectBlocks, blocks },
    prolongation: { prolongations },
    sheetCollectInfo: { isOpenMinHeader },
    statusesClientCar: { statuses },
    preReserve: { focusMileage }
}) => {
    return {
        textHeaders,
        carCollectBlocks,
        carDetailsInfo,
        showAnchors,
        showSecretary,
        carIdCollect,
        clientIdCollect,
        blocks,
        prolongations,
        isOpenMinHeader,
        statuses,
        focusMileage
    };
};

const mapDispatchToProps = {
    setShowAnchors,
    setShowSecretary,
    getPreReserves,
    setOpenMinHeader,
    getAllStatusesCarClients,
    updateClientPacketCycle
};

export default connect(mapStateToProps, mapDispatchToProps)(SheetCollectBody);
