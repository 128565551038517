import React, { useEffect, useState } from 'react';
import { Casco, Osago, Oils, Tires } from '../Anchors';
import HeadAnchors from '../HeadAnchors';
import { SetCarInterest } from '../../../../../components/Requests';
import PropTypes from 'prop-types';

import style from '../../SheetCollectBody.module.scss';
import BlocksWrap from '../BlocksWrap';
import activateProlongationForBlock from '../../../../../utils/activateProlongationForBlock';
import getBlockIdByLabel from '../../../../../utils/getBlockIdByLabel';
import { BLOCKS_LABELS } from '../../../../../constants/CarCollectBlocks';
import { differenceInYears } from 'date-fns';
import Diagnostics from '../Anchors/Diagnostics';

const AnchorsBlock = ({
    blocks,
    textHeaders,
    carDetailsInfo,
    showAnchors,
    carCollectBlocks,
    forSvBook,
    prolongations,
    handleUpdateProlongation,
    handleUpdatePacketCycle
}) => {
    const [isHidden, setIsHidden] = useState(false);

    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    const kacko = (
        <Casco
            id={getBlockIdByLabel(blocks, BLOCKS_LABELS.casco)}
            forSvBook={forSvBook}
            isProlongation={activateProlongationForBlock(getBlockIdByLabel(blocks, BLOCKS_LABELS.casco), prolongations)}
            handleUpdateProlongation={(value) => handleUpdateProlongation(value, getBlockIdByLabel(blocks, BLOCKS_LABELS.casco))}
            anchors
        />
    );

    const anchors = (
        <div>
            <Oils
                id={getBlockIdByLabel(blocks, BLOCKS_LABELS.changingEngineOil)}
                forSvBook={forSvBook}
                anchors
                isProlongation={activateProlongationForBlock(getBlockIdByLabel(blocks, BLOCKS_LABELS.changingEngineOil), prolongations)}
                handleUpdateProlongation={(value) => handleUpdateProlongation(value, getBlockIdByLabel(blocks, BLOCKS_LABELS.changingEngineOil))}
            />
            <Osago
                id={getBlockIdByLabel(blocks, BLOCKS_LABELS.osago)}
                forSvBook={forSvBook}
                isProlongation={activateProlongationForBlock(getBlockIdByLabel(blocks, BLOCKS_LABELS.osago), prolongations)}
                handleUpdateProlongation={(value) => handleUpdateProlongation(value, getBlockIdByLabel(blocks, BLOCKS_LABELS.osago))}
                anchors
            />
            {
                carDetailsInfo?.year
                    ? (carDetailsInfo?.year?.length === 4) &&
                    differenceInYears(new Date(), new Date(+carDetailsInfo?.year, 1, 1)) < process.env.REACT_APP_YEAR_CLOSE_KACKO
                        ? kacko
                        : ''
                    : kacko
            }
            <Tires
                forSvBook={forSvBook}
                anchors
                isProlongation={activateProlongationForBlock(getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService), prolongations)}
                handleUpdateProlongation={(value) => handleUpdateProlongation(value, getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService))}
            />
            <Diagnostics
                id={getBlockIdByLabel(blocks, BLOCKS_LABELS.diagnostics)}
                forSvBook={forSvBook}
                isProlongation={activateProlongationForBlock(getBlockIdByLabel(blocks, BLOCKS_LABELS.diagnostics), prolongations)}
                handleUpdateProlongation={(value) => handleUpdateProlongation(value, getBlockIdByLabel(blocks, BLOCKS_LABELS.diagnostics))}
                handleUpdatePacketCycle={handleUpdatePacketCycle}
                anchors
            />
        </div>
    );

    const interestsAnchorsNotCollect = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[1] : null) : null;

    return (
        <BlocksWrap forSvBook={forSvBook} KeyName='anchors' id='anchorCollectAnchors'>
            <>
                {
                    forSvBook
                        ? ''
                        : (
                            <HeadAnchors
                                bgStyle={style.headAnchor}
                                textHeader={textHeaders[0]}
                                id={getBlockIdByLabel(blocks, BLOCKS_LABELS.anchors)}
                                handleSetHidden={handleSetHidden}
                                stateBool={isHidden}
                            />
                        )
                }
                {
                    isHidden
                        ? ''
                        : showAnchors
                            ? anchors
                            : (
                                <SetCarInterest
                                    key='anchorsSetCarInterest'
                                    nameBlock=''
                                    interestsReasons={interestsAnchorsNotCollect}
                                    id={getBlockIdByLabel(blocks, BLOCKS_LABELS.anchors)}
                                    anchorsNotCollect
                                />
                            )
                }
            </>
        </BlocksWrap>
    );
};

AnchorsBlock.propTypes = {
    textHeaders: PropTypes.array.isRequired,
    blocks: PropTypes.array,
    prolongations: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    showAnchors: PropTypes.bool,
    carCollectBlocks: PropTypes.object,
    handleUpdateProlongation: PropTypes.func,
    handleUpdatePacketCycle: PropTypes.func,
    forSvBook: PropTypes.bool
};

export default AnchorsBlock;
