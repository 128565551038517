import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    carDetailsUpload,
    carIdCollectLoaded,
    clientIdCollectLoaded,
    fetchCollectBlocks,
    resetCarCollectId
} from '../../../actions';
import { saveReserveOrOrder } from '../../../reducers/preReserve';
import AnchorsBlock from '../../SheetCollect/SheetCollectBody/components/AnchorsBlock';
import { getPackets, updateClientPacketCycle } from '../../../reducers/packet';

const SvBookCollectContainer = ({
    carIdCollectLoaded,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    fetchCollectBlocks,
    carDetailsUpload,
    resetCarCollectId,
    carIdCollect,
    clientIdCollect,
    carIdRaw,
    textHeaders,
    carCollectBlocks,
    blocks,
    carDetailsInfo,
    showAnchors,
    packets,
    getPackets,
    updateClientPacketCycle,
    clientIdRaw = 0,
    reserveOrderRaw = {}
}) => {
    useEffect(() => {
        carIdRaw && carIdCollectLoaded(carIdRaw);
    }, [carIdCollectLoaded, carIdRaw]);

    useEffect(() => {
        !packets && getPackets(carIdCollect, clientIdCollect);
    }, [packets, getPackets, carIdCollect, clientIdCollect]);

    useEffect(() => {
        clientIdRaw && clientIdCollectLoaded(clientIdRaw);
    }, [clientIdCollectLoaded, clientIdRaw]);

    useEffect(() => {
        saveReserveOrOrder(reserveOrderRaw);
    }, [saveReserveOrOrder, reserveOrderRaw]);

    useEffect(() => {
        carIdCollect && carDetailsUpload(carIdCollect, clientIdCollect);
    }, [carIdCollect, clientIdCollect, carDetailsUpload]);

    useEffect(() => {
        return () => resetCarCollectId();
    }, [resetCarCollectId]);

    useEffect(() => {
        fetchCollectBlocks();
    }, [fetchCollectBlocks]);

    return (
        <AnchorsBlock
            blocks={blocks}
            textHeaders={textHeaders}
            carDetailsInfo={carDetailsInfo}
            showAnchors={showAnchors}
            carCollectBlocks={carCollectBlocks}
            handleUpdatePacketCycle={updateClientPacketCycle}
            forSvBook
        />
    );
};

SvBookCollectContainer.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsUpload: PropTypes.func,
    resetCarCollectId: PropTypes.func,
    saveReserveOrOrder: PropTypes.func,
    clientIdCollectLoaded: PropTypes.func,
    carIdCollectLoaded: PropTypes.func,
    carIdRaw: PropTypes.number,
    clientIdRaw: PropTypes.number,
    reserveOrderRaw: PropTypes.object,
    fetchCollectBlocks: PropTypes.func,
    textHeaders: PropTypes.array.isRequired,
    blocks: PropTypes.array,
    packets: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    showAnchors: PropTypes.bool,
    carCollectBlocks: PropTypes.object,
    updateClientPacketCycle: PropTypes.func,
    getPackets: PropTypes.func
};

const mapStateToProps = ({
    carDetailsState: { showAnchors, carDetailsInfo, carIdCollect, clientIdCollect },
    templatesOfFrameState: { textHeaders, carCollectBlocks, blocks },
    packet: { packets }
}) => {
    return {
        carIdCollect,
        clientIdCollect,
        textHeaders,
        carCollectBlocks,
        blocks,
        carDetailsInfo,
        showAnchors,
        packets
    };
};

export default connect(mapStateToProps, {
    fetchCollectBlocks,
    carDetailsUpload,
    resetCarCollectId,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    updateClientPacketCycle,
    carIdCollectLoaded,
    getPackets
})(SvBookCollectContainer);
