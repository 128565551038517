import {
    typeSetPackets,
    typeUpdateClientPacketCycle,
    typeUpdateClientPacketCycleSuccess,
    typeUpdateClientPacketCycleError
} from '../actionsTypes';

// ------------------------------------
// Constants
// ------------------------------------
const SET_PACKETS = typeSetPackets;
const UPDATE_CLIENT_PACKET_CYCLE = typeUpdateClientPacketCycle;
const UPDATE_CLIENT_PACKET_CYCLE_SUCCESS = typeUpdateClientPacketCycleSuccess;
const UPDATE_CLIENT_PACKET_CYCLE_ERROR = typeUpdateClientPacketCycleError;
// ------------------------------------
// Actions
// ------------------------------------

const setPackets = (packets) => {
    return {
        type: SET_PACKETS,
        payload: packets
    };
};

/* const setClientPacketCycle = (reference) => {
    return {
        type: UPDATE_CLIENT_PACKET_CYCLE,
        payload: reference
    };
}; */

const updateClientPacketCycleError = (isError) => {
    return {
        type: UPDATE_CLIENT_PACKET_CYCLE_ERROR,
        payload: isError
    };
};

const updateClientPacketCycleSuccess = (isSuccess) => {
    return {
        type: UPDATE_CLIENT_PACKET_CYCLE_SUCCESS,
        payload: isSuccess
    };
};

export const updateClientPacketCycle = (carId, clientId, packetCycleId, value) => (dispatch, getState, { apiService }) => {
    // for old collection sheets
    dispatch(updateClientPacketCycleError(false));
    dispatch(updateClientPacketCycleSuccess(true));
    const data = {
        client_id: clientId,
        packet_cycle_id: packetCycleId,
        value: `${value}`
    };

    return carId && clientId && apiService.updateClientPacketCycle(carId, data)
        .then((res) => {
            if (res) {
                dispatch(getPackets({
                    client_id: clientId,
                    car_id: carId
                }));
            }
            dispatch(updateClientPacketCycleSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(updateClientPacketCycleSuccess(false));
            dispatch(updateClientPacketCycleError(true));
            return err;
        });
};

export const getPackets = (carId, clientId) => (dispatch, getState, { apiService }) => {
    dispatch(updateClientPacketCycleError(false));
    dispatch(updateClientPacketCycleSuccess(true));
    const params = {
        car_id: carId,
        client_id: clientId
    };

    return carId && clientId && apiService.getPackets(params)
        .then((res) => {
            dispatch(setPackets(res));
            dispatch(updateClientPacketCycleSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(updateClientPacketCycleSuccess(false));
            dispatch(updateClientPacketCycleError(true));
            return err;
        });
};

export const actions = {
    updateClientPacketCycle,
    getPackets
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [UPDATE_CLIENT_PACKET_CYCLE_ERROR]: (state, action) => {
        return ({
            ...state,
            packetsError: true
        });
    },

    [UPDATE_CLIENT_PACKET_CYCLE_SUCCESS]: (state, action) => {
        return ({
            ...state,
            packetsLoading: true
        });
    },

    [SET_PACKETS]: (state, action) => {
        return ({
            ...state,
            packets: action.payload.packets,
            packetsError: false,
            packetsLoading: false
        });
    },

    [UPDATE_CLIENT_PACKET_CYCLE]: (state) => {
        return ({
            ...state
        });
    }
};

const packetReducer = (state, action) => {
    if (state === undefined) {
        return {
            packets: null,
            packetsError: false,
            packetsLoading: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.packet, action) : state.packet;
};

export default packetReducer;
