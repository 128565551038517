import React, { useEffect, useState } from 'react';
import style from '../../SheetCollectBody.module.scss';
import style2 from '../../../../SheetCollect/SheetCollectBody/components/Anchors/OilFields/OilsEveryMileage.module.scss';
import {
    SetCarInterest
} from '../../../../../components/Requests';
import PreReserve from '../../../../../components/PreReserve/PreReserve';
import getBlockIdByLabel from '../../../../../utils/getBlockIdByLabel';
import { BLOCKS_LABELS } from '../../../../../constants/CarCollectBlocks';
import ToggleRadio from '../../../../../components/CheckToggle/ToggleRadio';
import SubHeaderCollect from '../../../../../components/SubHeaderCollect';
import PropTypes from 'prop-types';
import { compose, withApiService } from '../../../../../Hoc';
import { connect } from 'react-redux';
import { fetchPostCarInsuranceDeadline } from '../../../../../actions';
import { ButtonsProlongation } from '../../../../../components/Buttons/ButtonsCommon';
import { CHANGE_MODE } from '../../../../../constants/prolongationMode';
import { BLOCK_LABEL_DIAGNOSTICS } from '../../../../../constants/carCollectBlockLabels';

const Diagnostics = ({
    id,
    forSvBook,
    isProlongation,
    anchors,
    handleUpdateProlongation,
    handleUpdatePacketCycle,
    blocks,
    textSubHeaders,
    carDetailsInfo,
    clientIdCollect,
    carCollectBlocks,
    packets
}) => {
    const [value, setValue] = useState(null);
    const [isHidden, setIsHidden] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [packetId, setPacketId] = useState(0);
    const [isValidationOn, setValidationOn] = useState(false);
    const interestsReasons = blocks ? blocks[2] : null;
    const idService = getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService);
    const [packet, setPacket] = useState(null);
    const [packetCycles, setPacketCycles] = useState([]);

    const handleValidationTurnOn = (turnOn) => {
        setValidationOn(turnOn);
    };

    useEffect(() => {
        const packetNew = packets?.find(p => p.id === packetId);
        !packet && packets && packetId && setPacket(packetNew);
        packetNew && setPacketCycles(packetNew?.packet_cycles);
    }, [packet, packets, packetId, setPacket]);

    useEffect(() => {
        !value && setValue('1');
    }, [value, carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        return () => setValidationOn(false);
    }, [carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        const resValidate = isProlongation !== null;
        setIsValid(resValidate);
    }, [carDetailsInfo, isProlongation]);

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    useEffect(() => {
        const block = carCollectBlocks &&
            carCollectBlocks.blocks &&
            carCollectBlocks.blocks.find(item => item.label === BLOCK_LABEL_DIAGNOSTICS);
        block && block.packet_id && setPacketId(block.packet_id);
    }, [carCollectBlocks]);

    const handleUpdate = (packetCycleId, value) => {
        setValue(value);
        carDetailsInfo?.id &&
        clientIdCollect &&
        handleUpdatePacketCycle(
            carDetailsInfo?.id, clientIdCollect, packetCycleId, value
        );
    };
    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };
    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationTires'
            id='prolongationTires'
            handleClick={(res) => handleUpdateProlongation(res)}
            btnSelected={CHANGE_MODE[isProlongation]}
            classes={['btnStretchCycle', 'btnTires', 'margin5', 'bgWhite', (isProlongation === null ? (isValidationOn ? 'redBorder' : 'notCheck') : '')]}
        />
    );

    const diagnostic = (
        <div className={style.wrapBodySubCollect}>
            <PacketCycles
                packetCycles={packetCycles}
                handleUpdate={handleUpdate}
                packetId={packetId}
            />
            {
                isProlongation
                    ? ''
                    : <SetCarInterest nameBlock='Интерес к услуге' interestsReasons={interestsReasons} id={id} />
            }
            { forSvBook
                ? ''
                : (
                    <PreReserve
                        packetId={packetId}
                        type='standard'
                        serviceName='Диагностику'
                        id='pre_reserve_diagnostic'
                        isNotValid={!isValid}
                        isValidationOn={isValidationOn}
                        handleValidationTurnOn={handleValidationTurnOn}
                        isProlongation={isProlongation}
                        handleUpdateProlongation={handleUpdateProlongation}
                    />
                ) }
        </div>
    );

    return (
        <div className={style.blockAnchors} id={idService}>
            <SubHeaderCollect
                title={getBlockIdByLabel(blocks, BLOCKS_LABELS.diagnostics, true).title || textSubHeaders[5]}
                handleSetHidden={handleSetHidden}
                blockId={id}
                stateBool={isHidden}
                anchors={anchors}
                actions={forSvBook ? [] : [prolongationButton]}
                classes={['withoutPadding']}
            >
                {
                    forSvBook
                        ? ''
                        : (
                            <ToggleRadio
                                text='Не собрано'
                                isActive={false}
                                key='isCollectTiresInfo'
                                id='isCollectTiresInfo'
                                handleClickActive={(res) => console.log('prolo nggggg', res)}
                            />
                        )
                }
            </SubHeaderCollect>
            {
                isHidden
                    ? ''
                    : diagnostic
            }
        </div>
    );
};

const PacketCycles = ({
    handleUpdate,
    packetCycles = []
}) => {
    const packetCyclesTemplate = packetCycles?.map(packetCycle => {
        const clientPacketCycle = (packetCycle?.client_packet_cycles &&
            Array.isArray(packetCycle?.client_packet_cycles) && packetCycle?.client_packet_cycles?.length > 0 &&
            packetCycle?.client_packet_cycles[0]) || null;

        return (
            <PacketCycle
                key={packetCycle?.id}
                packetCycleId={packetCycle?.id}
                packetCycleName={packetCycle?.cycle?.title}
                packetCycleValue={packetCycle?.value}
                cycleTitle={packetCycle?.cycle?.title}
                clientPacketCycleValue={clientPacketCycle?.value || null}
                handleUpdate={(value) => handleUpdate(packetCycle?.id, value)}
            />
        );
    });

    return (
        <div className={style.fields}>
            { packetCyclesTemplate }
        </div>
    );
};

PacketCycles.propTypes = {
    handleUpdate: PropTypes.func,
    packetCycles: PropTypes.array
};

const PacketCycleTemplate = ({
    packetCycleId,
    packetCycleName,
    value,
    handleSubmit,
    handleSetValue,
    cycleTitle = 'Цикличность'
}) => {
    return (
        <div id={'packet_cycle_' + packetCycleId} className={style.dateEnd}>
            <form
                key={'diagnostic_' + packetCycleName}
                onSubmit={handleSubmit}
                onBlur={handleSubmit}
                className={style2.formOilEveryMileage}
            >
                {
                    cycleTitle
                        ? (
                            <label
                                htmlFor={'diagnostic_' + packetCycleName}
                                className={style.labelChoice}
                            >
                                { cycleTitle }
                            </label>
                        )
                        : ''
                }
                <input
                    value={value || ''}
                    key={'diagnostic' + packetCycleName}
                    id={'diagnostic_' + packetCycleName}
                    className={style.oilCycleKm + (value ? '' : ' ' + style.textDisable)}
                    onChange={(e) => handleSetValue(e.target.value)}
                /> мес
            </form>
        </div>
    );
};

PacketCycleTemplate.propTypes = {
    packetCycleId: PropTypes.number,
    packetCycleName: PropTypes.string,
    value: PropTypes.string,
    cycleTitle: PropTypes.string,
    handleSubmit: PropTypes.func,
    handleSetValue: PropTypes.func
};

const PacketCycle = ({
    packetCycleId,
    packetCycleName,
    packetCycleValue,
    clientPacketCycleValue,
    handleUpdate,
    cycleTitle = 'Цикличность'
}) => {
    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        const val = clientPacketCycleValue || packetCycleValue;
        val && localValue === null && setLocalValue(val);
    }, [packetCycleValue, clientPacketCycleValue, localValue]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const val = clientPacketCycleValue || packetCycleValue;
        localValue && (localValue !== val) && handleUpdate(localValue);
    };

    const handleSetValue = (value) => {
        setLocalValue(value);
    };

    return (
        <PacketCycleTemplate
            cycleTitle={cycleTitle}
            packetCycleId={packetCycleId}
            packetCycleName={packetCycleName}
            value={localValue}
            handleSubmit={handleSubmit}
            handleSetValue={handleSetValue}
        />
    );
};

PacketCycle.propTypes = {
    packetCycleId: PropTypes.number,
    packetCycleName: PropTypes.string,
    packetCycleValue: PropTypes.string,
    clientPacketCycleValue: PropTypes.string,
    cycleTitle: PropTypes.string,
    handleUpdate: PropTypes.func
};

Diagnostics.propTypes = {
    textSubHeaders: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    carCollectBlocks: PropTypes.object,
    anchors: PropTypes.bool,
    forSvBook: PropTypes.bool,
    id: PropTypes.number,
    blocks: PropTypes.array,
    clientIdCollect: PropTypes.number,
    handleUpdateProlongation: PropTypes.func,
    handleUpdatePacketCycle: PropTypes.func,
    isProlongation: PropTypes.bool,
    packets: PropTypes.array
};

const mapStateToProps = ({
    carDetailsState: { carDetailsInfo, clientIdCollect },
    templatesOfFrameState: { textSubHeaders, blocks, carCollectBlocks },
    packet: { packets }
}) => {
    return {
        carDetailsInfo,
        textSubHeaders,
        carCollectBlocks,
        blocks,
        clientIdCollect,
        packets
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, { fetchPostCarInsuranceDeadline })
)(Diagnostics);
